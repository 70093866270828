import React, { useState, useEffect, Fragment, useCallback } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import authService from './api-authorization/AuthorizeService';
import '../main.css';
import AirServerList from './AirServerList';
import { Redirect } from 'react-router';
import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretLeft, faTrashAlt, faPen } from '@fortawesome/pro-regular-svg-icons';
import DetailsPane from './DetailsPane';
import CreateGroupPane from './CreateGroupPane';
import AddDevicePane from './AddDevicePane';
import ConfirmationDialog from './ConfirmationDialog';
import Notification from './Notification';
import { Tooltip, } from 'react-tippy';
import axios from 'axios';
import { cloneDeep } from 'lodash';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { HubConnectionState } from '@microsoft/signalr';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function MainPage({ detailsPaneVisible, createGroupPaneVisible, addingDevice, closeDetailsPane, openDetailsPane, selectedDeviceId, closeCreateGroupPane, handleNumSelectedDevices, multipleEdit,
    online, closeAddDevicePane, canManageUsers, numGroups }) {

    const [orgName] = useState(null);
	const [groups, set_groups] = useState(null);
    const [loading, set_loading] = useState(false);
    const [redirect, set_redirect] = useState(false);

    const [showRemoveGroupConfirmation, set_showRemoveGroupConfirmation] = useState(false);
    const [removeGroupConfirmationText, set_removeGroupConfirmationText] = useState('');

    const [groupIdForRemoval, set_groupIdForRemoval] = useState('');
    const [groupNameForRemoval, set_groupNameForRemoval] = useState('');
    const [groupIdForRenaming, set_groupIdForRenaming] = useState(null);
    const [newGroupName, set_newGroupName] = useState('');

    const [showTimeout, set_showTimeout] = useState(false);

    const MAINPAGE_TIMEOUT = 60000; // Timeout in milliseconds.
    const [hubConnection, set_hubConnection] = useState(null);

    useEffect(() => {
        if (groups === null && !loading) {
            fetchGroups();
        }
        numGroups(groups ? groups.length : 0);
    }, [groups, loading]);

    useEffect(() => {
        const SECOND = 1000;
        const MINUTE = 60 * SECOND;
        const HOUR = 60 * MINUTE;
        const DAY = 24 * HOUR;

        //let hubConnection = hubConn.current;

        //if (hubConnection) {
        //    console.log('Garbage collect old Notification server connection before attempting a new one');
        //    hubConnection.stop();
        //}

        const connection = new HubConnectionBuilder()
            .withUrl("/signalrhub", {
                accessTokenFactory: () => {
                    return authService.getAccessToken();
                }
            })
            .withAutomaticReconnect([
                0,
                2 * SECOND,
                10 * SECOND,
                30 * SECOND, 30 * SECOND, 30 * SECOND, 30 * SECOND, 30 * SECOND, 30 * SECOND,
                1 * MINUTE, 1 * MINUTE, 1 * MINUTE,
                1 * HOUR, 1 * HOUR, 1 * HOUR,
                6 * HOUR, 6 * HOUR, 6 * HOUR,
                1 * DAY, 1 * DAY, 1 * DAY])
            .build();

        connection.serverTimeoutInMilliseconds = 2 * 60 * 1000;  // 2 minutes.
        set_hubConnection(connection);
    }, []);

    useEffect(() => {
        if (hubConnection && groups && hubConnection.state === HubConnectionState.Disconnected) {
            hubConnection
                .start()
                .then(() => {
                    hubConnection.on('DeviceChanged', (deviceId, update) => {
                        console.debug("DeviceChanged", deviceId, update);
                        function isObject(item) {
                            return (item && typeof item === 'object' && !Array.isArray(item));
                        }

                        function mergeDeep(target, source) {
                            let output = Object.assign({}, target);
                            if (isObject(target) && isObject(source)) {
                                Object.keys(source).forEach(key => {
                                    if (isObject(source[key])) {
                                        if (!(key in target))
                                            Object.assign(output, { [key]: source[key] });
                                        else
                                            output[key] = mergeDeep(target[key], source[key]);
                                    } else {
                                        Object.assign(output, { [key]: source[key] });
                                    }
                                });
                            }
                            return output;
                        }

                        try {
                            const twin = JSON.parse(update);
                            if (!twin) return;
                            if (twin.tags) {
                                const twinIsGroup = twin.tags.deviceType === 'Group';
                                if (twinIsGroup) {
                                    // group action
                                    twin.tags.groupId = deviceId;
                                }
                                const groupId = twin.tags.groupId;
                                const group = groups.find(g => g.deviceId === groupId);
                                if (group) {
                                    console.log("group found for device:", group.groupName);
                                    if (twinIsGroup) {
                                        if (!twin.deviceId) {
                                            let groupIndex = groups.findIndex(g => g.deviceId === deviceId);
                                            groups.splice(groupIndex, 1);
                                        } else {
                                            group.groupName = twin.tags.groupName;
                                        }
                                    } else {
                                        let deviceIndex = group.devices.findIndex(d => d.deviceId === deviceId);
                                        if (deviceIndex !== -1) {
                                            let device = group.devices[deviceIndex];
                                            //console.log("exisiting device found:", device.config.device.name);
                                            device = mergeDeep(device, twin);
                                            group.devices[deviceIndex] = device;
                                        } else /*if (twin.config)*/ {
                                            //console.log("new device added", twin);
                                            if (twinIsGroup && (!twin.config || !twin.config.device)) {
                                                return;
                                            }
                                            let device = twin;
                                            // might have been moved
                                            for (let oldGroup of groups) {
                                                if (oldGroup.deviceId === groupId) {
                                                    continue;
                                                }
                                                console.debug(`checking group ${oldGroup.groupName}, ${oldGroup.deviceId}, for device: ${deviceId}`);
                                                const oldIndex = oldGroup.devices.findIndex(d => d.deviceId === deviceId);
                                                if (oldIndex !== -1) {
                                                    let oldDevice = oldGroup.devices.splice(oldIndex, 1)[0];
                                                    device = mergeDeep(oldDevice, twin);
                                                    console.debug("removing from older group", oldGroup.groupName);
                                                    break;
                                                }
                                            }
                                            if (group.devices.length > 0 || group.expandedInUI) {
                                                group.devices.push(device);
                                            } else {
                                                console.log("not adding device to unopened group:", group.groupName);
                                            }
                                        }
                                    }
                                } else {
                                    if (twin.deviceId == null) {
                                        console.log(`device: ${deviceId} removed`);
                                        for (let group of groups) {
                                            const deviceIndex = group.devices.findIndex(d => d.deviceId === deviceId);
                                            if (deviceIndex !== -1) {
                                                group.devices.splice(deviceIndex, 1);
                                                break;
                                            }
                                        }
                                    } else {
                                        // group added, and updated
                                        console.log(`group: ${twin.tags.groupName} added`);
                                        const group = { deviceId: deviceId, groupName: twin.tags.groupName, devices: [], selected: false };
                                        groups.push(group);
                                    }
                                }
                                const newGroups = cloneDeep(groups);
                                set_groups(newGroups);
                            } else if (twin.connectionState) {
                                for (let group of groups) {
                                    let device = group.devices.find(d => d.deviceId === deviceId);
                                    if (device) {
                                        if (device.connectionState !== twin.connectionState) {
                                            console.debug(`updating connectionState from ${device.connectionState} to ${twin.connectionState}`);
                                            device.connectionState = twin.connectionState;
                                            set_groups(cloneDeep(groups));
                                        }
                                        break;
                                    }
                                }
                            }

                        } catch (e) {
                            console.error("parsing deviceUpdate error", e);
                        }
                    });

                    hubConnection.onclose(function () {
                        console.log('Notification server disconnected');
                    });
                }
            )
            .catch(err => {
                console.log('Error while establishing connection. ' + JSON.stringify(err));
            });
        }

    }, [hubConnection, groups]);

    // MAIN PAGE SIGNALR NOTIFICATION SECTION DONE.



    //const pageTabSelectedAndNotMinimized = usePageVisibility();

    const displayNotification = (str) => {
        toast.info(str);
    }

    const displayError = (str) => {
        toast.error(str);
    }

    function closeMyDetailsPane() {
        closeDetailsPane();
    }

    function unselectDevices() {
        let tempGroups = [...groups]; // Shallow copy groups to trigger re-render with the call to set_groups() below.
        for (let group of tempGroups) {
            group.selected = false;
            for (let device of group.devices) {
                device.selected = false;
            }
        }
        set_groups(tempGroups);
        handleNumSelectedDevices(0);
    }

    const initGroups = useCallback((data) => {

        for (let group of data) {
            group.selected = false; // Add this new attribute.
            for (let device of group.devices) {
                device.selected = false; // Add this new attribute.
            }
        }
        set_groups(data);
    }, []);

    function removeGroupClick(groupId, group) {
        if (group.devices.length > 0) {
            set_removeGroupConfirmationText('This group contains devices. Removing the group will also detach its devices from Cloud Management. Please confirm.');
        } else {
            set_removeGroupConfirmationText('Are you sure you want to remove the group?');
        }

        set_showRemoveGroupConfirmation(true);
        set_groupIdForRemoval(groupId);
        set_groupNameForRemoval(group.groupName);
    }

    function removeGroupConfirmationResult(result) {
        set_showRemoveGroupConfirmation(false);
        if (result === true) {
            removeGroup(groupIdForRemoval)
        }
        set_groupIdForRemoval('');
        set_groupNameForRemoval('');
    }

    function startRenamingGroup(deviceId, groupName) {
        set_groupIdForRenaming(deviceId);
        set_newGroupName(groupName);
    }

    function fetchGroups() {
        if (showTimeout === true) return;
		authService.getAccessToken()
        .then(token => {
            set_showTimeout(false);
            let promise = axios.get(`CloudManagement/GetMainList`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
                timeout: MAINPAGE_TIMEOUT
            });
            online(true);
            return promise;
        })
        .then(response => {
            if (response.status === 401) { // Unauthorized.
                set_redirect(true);
            } else {
                handleNumSelectedDevices(0);
                return response.data;
            }
        })
        .then(data => {
            initGroups(data);
            set_loading(false);
        })
        .catch((error) => {
            if (error.message.includes('401')) { // Unauthorized.
                console.log('Logged out due to inactivity. Logging in again...');
                set_redirect(true);
            } else if (error.message.toLowerCase().includes('timeout')) {
                console.log('showTimeout fetching data on the main page');
                set_loading(false);

                set_groups([]);
                set_showTimeout(true);
            } else if (error.message === '404') {
				console.log('Organization Id not found from user id. Check the AspNetUsers table');
				set_loading(false);

				set_groups([]);
				displayError("Your user doesn't belong to any organization. Please check with administrator for help.")
			}
        });

        set_loading(true);
    }

    function isDeviceChecked(deviceId) {
        for (let group of groups) {
            let device = group.devices.find(d => d.deviceId === deviceId);
            if (device !== undefined) {
                return device.selected;
            }
        }
        // Should not get here but return undefined:
        return undefined;
    }

    function handleDeviceCheckChange(deviceId) {
        let temp = [...groups]; // Shallow copy groups to trigger re-render with the call to set_groups() below.

        for (let group of groups) {
            let device = group.devices.find(d => d.deviceId === deviceId);
            if (device !== undefined) {
                device.selected = !device.selected; // We should get here at some point.

                if (device.selected) {
                    // If all the devices in the group are selected, also select the group.
                    // So, if there is no other device in the group that's unselected, select the group:
                    let foundDevice = group.devices.find(d => !d.selected && d.deviceId !== device.config.deviceId);
                    if (!foundDevice) {
                        //console.log('Setting group to selected');

                        group.selected = true;
                    }
                } else {
                    // At least this device in the group is not selected so, unselect the group also:
                    group.selected = false;
                }

                set_groups(temp);
                break;
            }
        }

        handleNumSelectedDevices(countSelectedDevices());
    }

    function isGroupChecked(groupId) {
        let retval = groups.find(g => g.deviceId === groupId).selected;
        if (retval === undefined) {
            retval = false;
        }
        return retval;
    }

    function handleGroupCheckChange(groupId) {
        let temp = [...groups]; // Shallow copy groups to trigger re-render with the call to set_groups() below.

        const group = groups.find(g => g.deviceId === groupId);
        group.selected = !group.selected;

        // Selecting or unselecting a group will do the same for all its devices:
        for (let device of group.devices) {
            device.selected = group.selected;
        }

        set_groups(temp);

        handleNumSelectedDevices(countSelectedDevices());
    }

    function removeGroup(groupId) {
        let uri = `CloudManagement/RemoveGroup`;

        authService.getAccessToken()
            .then(token =>
                fetch(uri,
                    {
                        body: JSON.stringify(groupId),
                        headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Accept': 'application/json', 'Content-Type': 'application/json' },
                        method: 'DELETE'
                    })
            )
            .then(() => {
                displayNotification(`${groupNameForRemoval} removed`);
            })
            .catch((errorMsg) => {
                console.log(`RemoveGroup returned error: ${errorMsg}`);
            });

        // Remove the group from the array:
        removeGroupFromState(groupId);
    }

    function removeGroupFromState(groupId) {
        let temp = [...groups]; // Shallow copy groups to trigger re-render with the call to set_groups() below.
        let i = temp.findIndex(g => g.deviceId === groupId);
        if (i !== -1) {
            temp.splice(i, 1);
        }

        set_groups(temp);
    }

    function countSelectedDevices() {
        let count = 0;

        for (let group of groups) {
            for (let device of group.devices) {
                if (device.selected) {
                    count++;
                }
            }
        }

        return count;
    }

    function removeDevices() {
        const multipleDevices = {
            deviceIds: [],
            groupIds: [],
            updateAttributes: null
        };

        for (let group of groups) {
            if (group.selected) {
                multipleDevices.groupIds.push(group.deviceId);
                continue;
            }
            for (let device of group.devices) {
                if (device.selected) {
                    multipleDevices.deviceIds.push(device.config.deviceId);
                }
            }
        }

        removeDevicesFromBackend(multipleDevices);
        handleNumSelectedDevices(0);
    }

    function removeDevicesFromBackend(multipleDevices) {
        let uri = `CloudManagement/RemoveDevices`;
        authService.getAccessToken()
            .then(token =>
                fetch(uri,
                    {
                        body: JSON.stringify(multipleDevices),
                        headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Accept': 'application/json', 'Content-Type': 'application/json' },
                        method: 'DELETE'
                    })
            )
            .then(() => {
            })
            .catch((errorMsg) => {
                console.log(`RemoveDevices returned error: ${errorMsg}`);
            });
    }

    function removeDevice(deviceId) {
        const multipleDevices = {
            deviceIds: [],
            groupIds: [],
            updateAttributes: null
        };

        multipleDevices.deviceIds.push(deviceId);

        removeDevicesFromBackend(multipleDevices);
    }

    function fetchDevices(groupId) {
        authService.getAccessToken()
            .then(token => {
                let promise = axios.get(`CloudManagement/GetDevices/` + groupId, {
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
                    timeout: MAINPAGE_TIMEOUT
                });
                online(true);
                return promise;
            })
            .then(response => {
                if (response.status === 401) { // Unauthorized.
                    set_redirect(true);
                } else {
                    handleNumSelectedDevices(0);
                    return response.data;
                }
            })
            .then(data => {
                let temp = [...groups]; // Shallow copy groups.

                let group = groups.find(g => g.deviceId === groupId);
                group.devices = data;
                set_groups(temp);
                group.devices.fetching = false;
                group.devices.fetched = true;
            })
    }

    function toggleExpandedInUI(deviceId) {
        let temp = [...groups]; // Shallow copy groups.

        let group = groups.find(g => g.deviceId === deviceId);
        group.expandedInUI = !group.expandedInUI;

        set_groups(temp);

        if (group.devices.length === 0 && group.expandedInUI && !group.devices.fetched) {
            group.devices.fetching = true;
            fetchDevices(deviceId)
        }
    }

    function groupNameChanged(group) {
        set_groupIdForRenaming(null);
        set_newGroupName('');

        if (group.groupName !== newGroupName) {
            // Change the group name on the server:
            authService.getAccessToken()
                .then(token => {
                    fetch('CloudManagement/SetGroupName/' + group.deviceId,
                        {
                            body: JSON.stringify({ groupName: newGroupName }),
                            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Accept': 'application/json', 'Content-Type': 'application/json' },
                            method: 'PUT'
                        });
                });

            // Change the group name in the UI:
            let temp = [...groups]; // Shallow copy groups.
            group.groupName = newGroupName;

            set_groups(temp);
        }
    }

    function typingGroupName(groupNameValue) {
        set_newGroupName(groupNameValue);
    }

    function handleGroupKeyPress(e, group) {
        if (e.key === 'Enter') {
            groupNameChanged(group);
        }
    }

    if (redirect) {
        return (<Redirect to={ApplicationPaths.Login}/>);
    }

    return (
        <div className='devicesDiv'>
            <div className='spaceDiv'></div>
            {
                loading || groups === null
                    ? (<Loader type="TailSpin" color="#00BFFF" height={30} width={30} timeout={MAINPAGE_TIMEOUT} />)
                    : (<div>
                        {groups.length === 0 ? (<div></div>) : groups.map(group =>
                            <Container key={group.deviceId} className='groupContainer'>
                                <Row className='groupNameRow'>
                                    <Col className='groupCol'>
                                        <span>
                                            {
                                                groupIdForRenaming === group.deviceId ?
                                                    (<Form.Control type='text' value={newGroupName} onChange={(e) => { typingGroupName(e.target.value) }} maxLength='29'
                                                        onBlur={() => { groupNameChanged(group) }} autoFocus onFocus={(e) => e.target.select()} className='lblGroup'
                                                        onKeyPress={(e) => { handleGroupKeyPress(e, group); }} />) :
                                                    (<h4 className='groupNameHeader'>{group.groupName}</h4>)
                                            }

                                            {
                                                (selectedDeviceId === '' && canManageUsers) ? (
                                                    <Fragment>
                                                        <Tooltip title='Rename group' position='bottom' trigger='mouseenter' arrow='true'>
                                                            <span>
                                                                <FontAwesomeIcon icon={faPen} size='lg' className={groupIdForRenaming === null ? 'hoverButton' : 'invisible'}
                                                                    onClick={() => startRenamingGroup(group.deviceId, group.groupName)} />
                                                            </span>
                                                        </Tooltip>

														<Tooltip title='Remove group' position='bottom' trigger='mouseenter' arrow='true'>
															<span>
																<FontAwesomeIcon icon={faTrashAlt} size='lg'
																	className={groupIdForRenaming === null ? 'hoverButton trashButton' : 'invisible'}
																	onClick={() => removeGroupClick(group.deviceId, group)} />
															</span>
														</Tooltip>
                                                    </Fragment>
                                                )
                                                : null
                                            }

                                            <ConfirmationDialog header='Remove Group' show={showRemoveGroupConfirmation} text={removeGroupConfirmationText} result={removeGroupConfirmationResult} />
                                        </span>
                                    </Col>
                                    <Col>
                                        <FontAwesomeIcon icon={group.expandedInUI ? faCaretDown : faCaretLeft} size='2x' className='expandButton' onClick={() => toggleExpandedInUI(group.deviceId)} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <AirServerList devices={group.devices} detailsPaneVisible={detailsPaneVisible} openDetailsPane={openDetailsPane} groupId={group.deviceId}
                                            isDeviceChecked={isDeviceChecked} handleDeviceCheckChange={handleDeviceCheckChange}
                                            isGroupChecked={isGroupChecked} handleGroupCheckChange={handleGroupCheckChange} expandedInUI={group.expandedInUI} />
                                    </Col>
                                </Row>
                            </Container>
                        )}

                        <DetailsPane paneVisible={detailsPaneVisible} closeDetailsPane={closeMyDetailsPane} deviceId={selectedDeviceId} multipleEdit={multipleEdit}
                            groups={groups} unselectDevices={unselectDevices} removeDevice={removeDevice} removeDevices={removeDevices}
                            displayNotification={displayNotification} displayError={displayError} set_showTimeout={set_showTimeout} orgName={orgName} />

						<CreateGroupPane paneVisible={createGroupPaneVisible} closeCreateGroupPane={closeCreateGroupPane} displayNotification={displayNotification}
							displayError={displayError} />

                        <AddDevicePane paneVisible={addingDevice} groups={groups} set_groups={set_groups} closeAddDevicePane={closeAddDevicePane} displayError={displayError} />

                        <ToastContainer pauseOnFocusLoss={false} hideProgressBar={true} autoClose={5000} />

                        <div className='notification-container'>
                            <Notification msg='Server is busy. Try again later.' showNotification={showTimeout} set_showNotification={set_showTimeout} left='400px' type='error' width='250px' duration={3000}></Notification>
                        </div>
                    </div>)
            }
        </div>
    );
}
